/* eslint-disable react/no-danger */
import React, { Component, createRef } from 'react';
import { graphql } from 'gatsby';
import { OutboundLink as Anchor } from 'gatsby-plugin-google-analytics';
import { FormattedDate, FormattedMessage } from '@react-intl';
import PropTypes from '@prop-types';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Author = styled('address')``;
const Avatar = styled('img')``;
const Content = styled('div')``;
const Date = styled('time')``;
const Header = styled('header')``;
const Icon = styled('img')``;
const Image = styled('img')``;
const Label = styled('span')``;
const Link = styled(Anchor)``;
const Share = styled('aside')``;
const Title = styled('h1')``;
const Wrapper = styled('div')``;

class Post extends Component {
  content = createRef();

  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.shape({
      post: PropTypes.shape({
        authors: PropTypes.arrayOf(PropTypes.string),
        excerpt: PropTypes.string,
        feature_image: PropTypes.string,
        html: PropTypes.string,
        meta_description: PropTypes.string,
        meta_title: PropTypes.string,
        og_description: PropTypes.string,
        og_image: PropTypes.string,
        og_title: PropTypes.string,
        published_at: PropTypes.string,
        slug: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
        title: PropTypes.string,
        twitter_description: PropTypes.string,
        twitter_image: PropTypes.string,
        twitter_title: PropTypes.string,
        updated_at: PropTypes.string,
      }),
    }),
  };

  componentDidMount() {
    this.content.current.querySelectorAll('script').forEach(inert => {
      const script = document.createElement('script');
      script.src = inert.src;
      script.innerHTML = inert.innerHTML;

      inert.parentNode.insertBefore(script, inert);
    });
  }

  render() {
    const {
      className,
      data: {
        post: {
          authors,
          excerpt,
          feature_image: image,
          html,
          meta_description: metaDescription,
          meta_title: metaTitle,
          og_description: OGDescription,
          og_image: OGImage,
          og_title: OGTitle,
          published_at: date,
          slug,
          tags,
          title,
          twitter_description: twitterDescription,
          twitter_image: twitterImage,
          twitter_title: twitterTitle,
          updated_at: updatedAt,
        },
      },
    } = this.props;

    const author = authors[0];
    const keywords = tags.map(({ name }) => name);
    const url = `https://joined.app/blog/${slug}`;

    const uri = {
      title: encodeURIComponent(metaTitle || title),
      url: encodeURIComponent(url),
    };

    return (
      <>
        <Helmet>
          {/* Set the document's title */}
          <title>{metaTitle || title}</title>
          <meta property="article:published_time" content={date} />
          <meta property="article:modified_time" content={updatedAt} />

          {/* Short description of the document (limit to 150 characters) */}
          {/* This content *may* be used as a part of search engine results. */}
          <meta name="description" content={metaDescription} />

          <meta
            property="og:description"
            content={OGDescription || metaDescription || excerpt}
          />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={OGImage || image} />
          <meta property="og:site_name" content="Joinedapp" />
          <meta property="og:title" content={OGTitle || metaTitle || title} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={url} />

          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:description"
            content={twitterDescription || excerpt}
          />
          <meta name="twitter:image" content={twitterImage || image} />
          <meta name="twitter:title" content={twitterTitle || title} />
          <meta name="twitter:url" content={url} />
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content="Joinedapp" />

          <link rel="canonical" href={url} />

          <script
            dangerouslySetInnerHTML={{
              __html: `
              {
                "@context": "https://schema.org",
                "@type": "Article",
                "publisher":{
                  "@type": "Organization",
                  "name": "Joinedapp",
                  "logo": "https://joined.app/images/logotype.svg"
                },
                "author":{
                  "@type": "Organization",
                  "name": "Joinedapp",
                  "url": "https://joined.app/"
                },
                "headline": "${title}️",
                "url": "https://joined.app/blog/${slug}/",
                "datePublished": "${date}",
                "dateModified": "${updatedAt}",
                "image":{
                  "@type": "ImageObject",
                  "url": "${image}"
                },
                "keywords": "${keywords}",
                "description": "${metaDescription || excerpt}",
                "mainEntityOfPage":{
                  "@type": "WebPage",
                  "@id": "https://joined.app/"
                }
              }
            `,
            }}
            type="application/ld+json"
          />
        </Helmet>
        <article className={className}>
          <Header>
            <Share>
              <FormattedMessage id="SHARE" uppercase>
                Share
              </FormattedMessage>
              <Link
                href={`https://www.linkedin.com/shareArticle?mini=true&summary=${uri.title}&title=${uri.title}&url=${uri.url}`}
                rel="noopener"
                target="_blank"
              >
                <Icon
                  alt={title}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                  style={{ backgroundImage: `url(/images/linkedin.svg)` }}
                />
              </Link>

              <Link
                href={`https://www.facebook.com/sharer/sharer.php?u=${uri.url}`}
                rel="noopener"
                target="_blank"
              >
                <Icon
                  alt={title}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                  style={{ backgroundImage: `url(/images/facebook.svg)` }}
                />
              </Link>
              <Link
                href={`https://twitter.com/share?text=${uri.title}&url=${uri.url}`}
                rel="noopener"
                target="_blank"
              >
                <Icon
                  alt={title}
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                  style={{ backgroundImage: `url(/images/twitter.svg)` }}
                />
              </Link>
            </Share>
            <Title>{title}</Title>
            <FormattedDate
              component={Date}
              day="numeric"
              datetime={date}
              month="long"
              value={date}
              year="numeric"
            />
          </Header>
          {image && (
            <Image
              alt={title}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
            />
          )}
          <Content
            dangerouslySetInnerHTML={{ __html: html }}
            ref={this.content}
          />
          <Author>
            <Avatar
              alt={author.name}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
              style={{ backgroundImage: `url(${author.profile_image})` }}
            />
            <Wrapper>
              {author.name}
              {author.website && (
                <Link href={author.website}>{author.website}</Link>
              )}
            </Wrapper>
          </Author>
        </article>
      </>
    );
  }
}

export const query = graphql`
  query($id: String!) {
    post: ghostPost(id: { eq: $id }) {
      authors {
        name
        profile_image
        website
      }
      excerpt
      feature_image
      html
      meta_description
      meta_title
      og_description
      og_image
      og_title
      published_at
      slug
      tags {
        name
      }
      title
      twitter_description
      twitter_image
      twitter_title
      updated_at
    }
  }
`;

function resize(image, size) {
  return image.replace('/images/', `/images/size/w${size}/`);
}

export default styled(Post).attrs(props => ({
  image: (props.data && props.data.post && props.data.post.feature_image) || '',
}))`
  margin: 0 auto;
  max-width: 128rem;
  padding: 2.4rem;
  position: relative;

  ${Header} {
    margin-bottom: 2.4rem;
  }

  ${Date} {
    color: ${rgba('#000f19', 0.4)};
    display: inline-block;
    font-size: 1.2rem;
    margin-left: 0.2rem;

    ${Label} {
      display: inline-block;
      text-indent: -9999px;
    }
  }

  ${Share} {
    color: ${rgba('#000f19', 0.4)};
    display: flex;
    align-items: flex-end;

    ${Icon} {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      margin-left: 0.8rem;
      height: 2rem;
      width: 2rem;

      &:first-of-type {
        margin-left: 1.6rem;
      }
    }
  }

  ${Title} {
    font-size: 2.8rem;
    line-height: 3.2rem;
    margin-top: 1.6rem;
  }

  ${Image} {
    background-image: url("${props => resize(props.image, 600)}");
    background-position: center;
    background-size: cover;
    height: 100vw;
    margin: 0 -2.4rem 4vw;
    width: calc(100% + 4.8rem);
  }
  
  ${Author} {
    color: ${rgba('#000f19', 0.4)};
    display: flex;
    align-items: center;
    width: 100%;
    font-style: normal;
    
    ${Avatar} {
      background-position: center;
      background-size: contain;
      border-radius: 50%;
      height: 5rem;
      margin-right: 0.8rem;
      width: 5rem;
    }
    
    ${Link} {
      color: #02c0f9;
      display: block;
    }
  }

  @media screen and (min-width: 480px) {
    padding: 3.2rem 6.4rem;

    ${Header} {
      margin-bottom: 3.2rem;
    }

    ${Title} {
      font-size: 4.8rem;
      line-height: 5.5rem;
      margin-top: 1.6rem;
    }

    ${Image} {
      height: 36rem;
      margin: 0 -6.4rem 4vw;
      width: calc(100% + 12.8rem);
    }

    ${Content} {
      font-size: 1.6rem;
    }
  }

  @media screen and (min-width: 840px) {
    padding: 3.2rem 12.8rem;

    ${Image} {
      background-image: url("${props => resize(props.image, 1000)}");
      height: 48rem;
      margin: 0 -12.8rem 4vw;
      width: calc(100% + 25.6rem);
    }

    ${Content} {
      font-size: 1.8rem;
    }
  }

  @media screen and (min-width: 1280px) {
    display: flex;
    flex-wrap: wrap;
    padding: 6.4rem 3.2rem;

    ${Header}, ${Content}, ${Author} {
      padding-left: 39.2rem;
      width: 100%;
    }

    ${Image} {
      background-image: url("${props => resize(props.image, 2000)}");
      border-radius: 0.6rem;
      height: 56rem;
      left: 3.2rem;
      position: absolute;
      margin: 0;
      top: 6.4rem;
      width: 36rem;
    }

    ${Content} {
      flex: 1;
      font-size: 1.8rem;
    }
  }

  /* Markdown */

  ${Content} {
    line-height: 1.75;
    position: relative;

    a {
      color: #02c0f9;
    }

    blockquote {
      margin: 0 0 1.5em;
      padding: 0 1.5em;
      border-left: 0.3rem solid #02c0f9;
    }

    strong {
      font-weight: 600;
    }

    code {
      background: #e5eff5;
      border-radius: 2px;
      font-size: 0.8rem;
      font-weight: 400;
      line-height: 1.2rem;
      padding: 0.2rem 0.5rem;
      vertical-align: middle;
      word-break: break-all;
    }

    figure {
      margin: 1.2rem 0 2.4rem;
      max-width: 100%;
      text-align: center;

      &.kg-embed-card {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      figcaption {
        color: ${rgba('#000f19', 0.4)};
        font-size: 0.85em;
      }
    }

    h1,
    h2 {
      line-height: 1.15;
      font-weight: 600;
      margin: 2em 0 0.2em;
    }

    h1 {
      font-size: 2.8rem;
    }

    h2 {
      font-size: 2.6rem;
    }

    hr {
      border: 0;
      border-top: 0.1rem solid #e3e9ed;
      height: 0.1rem;
      margin: 4vw 0;
    }

    iframe {
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }

    ol,
    ul {
      margin-bottom: 1.5em;
      padding: 0 1.5em 0 1.3em;

      li {
        margin: 0.5em 0;
        padding-left: 0.3em;
      }
    }

    p {
      font-size: 1.4rem;
      margin: 0 0 1.5em;

      &:first-child {
        font-size: 1.25em;
        line-height: 1.5em;
      }
    }

    pre {
      background: ${rgba('#000f19', 0.8)};
      border-radius: 2px;
      color: #fefefe;
      font-size: 1.2rem;
      line-height: 1.5em;
      margin: 1.5em 0 3em;
      max-width: 100%;
      overflow-x: auto;
      padding: 2rem;

      code {
        background: transparent;
        font-size: inherit;
      }
    }

    @media screen and (min-width: 480px) {
      h2 {
        font-size: 3.6rem;
      }

      p {
        font-size: 1.8rem;
      }
    }
  }
`;
